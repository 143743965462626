import { useQuery } from "@tanstack/react-query";
import useCompanyIdFromUrl from "@/hooks/companies/useCompanyIdFromUrl";
import apiClient from "@/modules/request.module.v2";

export default (userCommId) => {
	const companyId = useCompanyIdFromUrl();

	return useQuery({
		queryKey: ["sendEmailJobs", companyId, userCommId],
		queryFn: () => {
			const url = userCommId
				? `notifications/send-email-jobs/user-comm/${userCommId}`
				: `notifications/send-email-jobs`;

			return apiClient.get(url, {
				headers: {
					"x-company-id": companyId,
				},
			});
		},
		refetchInterval: ({ state }) => {
			const cachedData = state?.data?.data;

			if (!cachedData) {
				return 15 * 1000; // 15 seconds
			}

			const hasPendingJobs = cachedData.some(
				(job) =>
					!["delivered", "not_delivered", "failed"].includes(
						job.sendGrid?.status,
					),
			);

			if (hasPendingJobs) {
				return 10 * 1000;
			}

			return false;
		},
	});
};
