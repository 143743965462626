import React from "react";
import useUserComm from "@/hooks/notifications/useUserComm";
import DialogModal from "@/components/dialogModal";
import useCreateSendEmailJobs from "@/hooks/notifications/useCreateSendEmailJobs";
import useSendEmailJobPreviews from "../../hooks/notifications/useSendEmailJobPreviews";
import { ItemList, ItemListColumn, ItemListRow } from "../itemList";
import i18n from "@/i18n";
import { Badge } from "../ui/badge";
import { X } from "lucide-react";
import { Button } from "../ui/button";
import useUpdateUserComm from "@/hooks/notifications/useUpdateUserComm";

const { t } = i18n;

const UserCommSendPanel = ({ userCommId, onCancel, onComplete, editor }) => {
	const { data: userCommQuery, isLoading: isLoadingUserComm } =
		useUserComm(userCommId);
	const userComm = userCommQuery?.data;
	const userCommRecipientsById = userComm?.recipients.reduce(
		(acc, r) => ({ ...acc, [r.id]: r }),
		{},
	);

	const {
		mutateAsync: createSendEmailJobs,
		error,
		isPending: isCreatingSendEmailJobs,
	} = useCreateSendEmailJobs("userComm", userCommId);
	const createSendEmailJobsError = error?.response?.data;

	const {
		data: sendEmailJobPreviews,
		isLoading: isLoadingUseSendEmailJobPreviews,
	} = useSendEmailJobPreviews(userComm.recipients, editor);

	const { mutateAsync: updateUserComm } = useUpdateUserComm(userCommId);

	const isLoading = isLoadingUserComm || isLoadingUseSendEmailJobPreviews;

	const sendableEmailJobs = sendEmailJobPreviews.filter(
		(preview) => preview.email?.length > 1,
	);
	const sendableEmailCount = sendableEmailJobs.length;

	return (
		<DialogModal
			open
			size="sm"
			onCancel={onCancel}
			isLoading={isLoading}
			title="Send Emails"
			submitText={`Send ${sendableEmailCount} emails`}
			isSubmitting={isCreatingSendEmailJobs}
			onSubmit={async () => {
				const jobs = sendableEmailJobs.map((preview) => ({
					email: preview.email,
					subject: userComm.subject,
					body: preview.body,
					recipientReference: {
						type: "investment",
						id: preview.id,
					},
				}));

				await createSendEmailJobs(jobs, {
					onSuccess: () => {
						onComplete();
					},
				});
			}}
			bodyRenderer={() => {
				return (
					<div className="flex flex-col gap-4">
						{createSendEmailJobsError?.name ===
							"SendEmailJobsValidationError" && (
							<div className="flex flex-col gap-4">
								<div>
									The following problems are preventing the emails from being
									sent:
								</div>
								<ItemList columnSizing="1fr 1fr 40px" rightAlignLastColumn>
									{createSendEmailJobsError?.errors
										?.filter(
											(error) =>
												userCommRecipientsById[error.recipientReference?.id],
										)
										.map((error) => (
											<ItemListRow key={error.id}>
												<ItemListColumn>{error.email}</ItemListColumn>
												<ItemListColumn className="flex gap-2">
													{Object.values(error.errors).map((e) => (
														<Badge
															size="sm"
															variant="outline-destructive"
															key={e.path}
														>
															{t([
																`send_email_job.validation_errors.${e.path}`,
																"send_email_job.validation_errors.unspecified",
															])}
														</Badge>
													))}
												</ItemListColumn>
												<ItemListColumn>
													<Button
														title="Remove recipient"
														size="sm"
														variant="ghost"
														onClick={() => {
															updateUserComm({
																recipients: userComm.recipients.filter(
																	(r) => r.id !== error.recipientReference?.id,
																),
															});
														}}
													>
														<X />
													</Button>
												</ItemListColumn>
											</ItemListRow>
										))}
								</ItemList>
							</div>
						)}
						<div>
							Are you sure you want to send {sendableEmailCount} emails. Note
							that emails will only be sent to recipients with valid email
							addresses.
						</div>
					</div>
				);
			}}
		/>
	);
};

export default UserCommSendPanel;
