import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import tippy from "tippy.js";
import i18n from "@/i18n";

import { Node } from "@tiptap/core";
import { PluginKey } from "@tiptap/pm/state";
import Suggestion from "@tiptap/suggestion";
import {
	ReactRenderer,
	NodeViewWrapper,
	ReactNodeViewRenderer,
} from "@tiptap/react";

import { cn } from "@/components/utils";
import { Input } from "@/components/ui/input";
import DialogModal from "@/components/dialogModal";
import Tooltip from "@/components/tooltip";

const { t } = i18n;

const PlaceholderFieldBadge = ({ id, title, onClick }) => (
	<Tooltip value={title} delayDuration={0}>
		<span
			title={title}
			onClick={onClick}
			className={cn(
				"border rounded p-1 bg-blue-100 border-blue-300 text-blue-700",
				{ "cursor-pointer": typeof onClick === "function" },
			)}
		>
			{id}
		</span>
	</Tooltip>
);

const PlaceholderCalculationBadge = ({ value, onClick }) => (
	<span
		className={cn(
			"border rounded p-1 bg-purple-100 border-purple-300 text-purple-700",
			{ "cursor-pointer": typeof onClick === "function" },
		)}
		onClick={onClick}
	>
		{value}
	</span>
);

const DEFAULT_FIELDS = [
	{
		id: "nameFull",
		description: "The recipient's full name.",
		type: "string",
	},
	{
		id: "nameFirst",
		description: "The recipient's first name",
		type: "string",
	},
	{
		id: "nameLast",
		description: "The recipient's last name.",
		type: "string",
	},
	{
		id: "emailAddress",
		description: "The recipient's email address.",
		type: "string",
	},
	{
		id: "bankInfo",
		description: "The recipient's bank information.",
		type: "string",
	},
	{
		id: "sharesTotal",
		description: "The recipient's total amount of shares held.",
		type: "number",
	},
	{
		id: "sharesPercentageTotal",
		description: "The recipient's total percentage of shares held.",
		type: "number",
	},
];

const PlaceholderPluginKey = new PluginKey("PlaceholderPluginKey");

const PlaceholderNodeViewRenderer = (props) => {
	const { node, editor, extension } = props;

	const isCalculation = node.attrs.name === "calculation";
	const readOnly = extension.options.readOnly;

	const justCreated = !!(
		node.attrs.createdAt && Date.now() - node.attrs.createdAt < 2000
	);
	const [isOpen, setIsOpen] = useState(justCreated);
	const [inputValue, setInputValue] = useState(node.attrs.value);

	const inputRef = useRef(null);

	useEffect(() => {
		editor.setOptions({ editable: !readOnly && !isOpen });
	}, [isOpen]);

	const allowedFields = extension?.options?.fields?.filter?.(
		(f) => f.type === "number",
	);

	let inputValueIsValid;
	try {
		// Try evaluating the input value by
		// using a default value of 10 for the placeholders
		const interpolatedValue = allowedFields.reduce(
			(acc, field) => acc.replace(new RegExp(field.id, "g"), 10),
			inputValue,
		);
		inputValueIsValid = eval(interpolatedValue);
	} catch (e) {
		inputValueIsValid = false;
	}

	const validationMessage = inputValueIsValid
		? " "
		: t("email_editor.placeholders.calculation.input.invalid");
	const validationVariant = inputValueIsValid ? "" : "destructive";

	const onSave = () => {
		if (!inputValueIsValid) {
			return;
		}

		if (inputValue !== node.attrs.value) {
			props.updateAttributes({ value: inputValue });
		}

		setIsOpen(false);
		editor.chain().focus().run();
	};

	return (
		<NodeViewWrapper className="inline">
			{isOpen && (
				<DialogModal
					size="sm"
					title={t("email_editor.placeholders.calculation.actions.edit")}
					onSubmit={onSave}
					onCancel={() => setIsOpen(false)}
					bodyRenderer={() => (
						<div className="flex flex-col gap-4">
							<Input
								autoFocus
								ref={inputRef}
								validationMessage={validationMessage}
								validationVariant={validationVariant}
								className="font-mono bg-slate-100 text-center"
								value={inputValue}
								onChange={(evt) => {
									console.log("inputValue", evt);
									const newInputValue = evt.target.value.replace(/[,]/g, ".");
									setInputValue(newInputValue);
								}}
								onKeyDown={(evt) => {
									if (evt.key === "Enter") {
										onSave(evt);
									}
								}}
							/>
							<div>
								The following fields are available for use in calculations.
								Click on a field to insert it:
							</div>
							<div className="flex gap-2">
								{allowedFields.map((field) => (
									<PlaceholderFieldBadge
										key={field.id}
										id={field.id}
										title={field.description}
										onClick={() => {
											setInputValue(`${inputValue}${field.id}`);
											inputRef.current?.focus();
										}}
									/>
								))}
							</div>
						</div>
					)}
				/>
			)}
			{isCalculation ? (
				<PlaceholderCalculationBadge
					onClick={() => setIsOpen(!isOpen)}
					value={node.attrs.value}
				/>
			) : (
				<PlaceholderFieldBadge id={node.attrs.name} />
			)}
		</NodeViewWrapper>
	);
};

export const PlaceholderSuggestionList = forwardRef((props, ref) => {
	const items = props.items;
	const [selectedIndex, setSelectedIndex] = useState(0);

	const selectItem = (index) => {
		const item = items[index];

		if (item) props.command({ id: item.id, type: item.type });
	};

	useEffect(() => setSelectedIndex(0), [items]);

	useImperativeHandle(ref, () => ({
		onKeyDown: ({ event }) => {
			if (event.key === "ArrowUp") {
				setSelectedIndex((selectedIndex + items.length - 1) % items.length);
				return true;
			}

			if (event.key === "ArrowDown") {
				setSelectedIndex((selectedIndex + 1) % items.length);
				return true;
			}

			if (event.key === "Enter") {
				selectItem(selectedIndex);
				return true;
			}

			return false;
		},
	}));

	return (
		<div className="bg-white p-2 border border-edge rounded shadow-md">
			{items.map((item, idx) => (
				<div
					onClick={() => selectItem(idx)}
					onMouseEnter={() => setSelectedIndex(idx)}
					key={item.id}
					className={cn(
						"flex flex-col items-start gap-2 p-2 rounded cursor-pointer",
						{
							"bg-slate-100": idx === selectedIndex,
						},
					)}
				>
					{item.type === "calculation" ? (
						<PlaceholderCalculationBadge id={item.id} value={item.id} />
					) : (
						<PlaceholderFieldBadge id={item.id} />
					)}
					<div className="text-sm text-foreground/70">{item.description}</div>
				</div>
			))}
		</div>
	);
});
PlaceholderSuggestionList.displayName = "PlaceholderSuggestionList";

export const PlaceholderValue = Node.create({
	name: "placeholder",
	group: "inline",
	inline: true,
	atom: true,

	addOptions() {
		return {
			record: {},
		};
	},

	addAttributes() {
		return {
			name: { default: "" },
			value: { default: "" },
		};
	},

	parseHTML() {
		return [{ tag: "span[data-variable]" }];
	},

	renderHTML({ node }) {
		const record = this.options.record;
		const variable = node.attrs.name;
		const isCalculation = variable === "calculation";

		let value, rawValue;

		if (isCalculation) {
			const allowedFields = DEFAULT_FIELDS.filter(
				(f) => f.type === "number",
			).map((f) => f.id);

			const interpolatedCalculation = allowedFields.reduce(
				(acc, field) => acc.replace(new RegExp(field, "g"), record[field] ?? 0),
				node.attrs.value,
			);
			try {
				const evaluationResult = eval(interpolatedCalculation);

				value = evaluationResult.toLocaleString("sv-SE", {
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				});
			} catch (e) {
				value = `Error: ${interpolatedCalculation}`;
			}
			rawValue = value;
		} else {
			rawValue = record?.[variable];
			value = String(rawValue ?? `N/A (${variable})`);
		}

		return [
			"span",
			{
				"data-variable": node.attrs.name,
				class:
					typeof rawValue === "undefined" ? "bg-red-100 text-red-500" : null,
			},
			value,
		];
	},
});

export const Placeholder = Node.create({
	name: "placeholder",
	group: "inline",
	inline: true,
	atom: true,

	addOptions() {
		return {
			fields: DEFAULT_FIELDS,
			suggestion: {
				char: "/",
				items: ({ editor, query }) => {
					const fields = editor.extensionManager.extensions.find(
						(e) => e.name === "placeholder",
					)?.options?.fields;

					const allFields = [
						{
							id: "calculation",
							description: "Insert a dynamic calculation",
							type: "calculation",
						},
						...fields,
					];

					return allFields.filter((field) =>
						field.id.toLowerCase().includes(query.toLowerCase()),
					);
				},
				render: () => {
					let component;
					let popup;

					return {
						onStart: (props) => {
							component = new ReactRenderer(PlaceholderSuggestionList, {
								props,
								editor: props.editor,
							});

							popup = tippy(props.editor.view.dom, {
								getReferenceClientRect: props.clientRect,
								content: component.element,
								showOnCreate: true,
								interactive: true,
								trigger: "manual",
							});
						},
						onUpdate: (props) => {
							component.updateProps(props);
							popup.setProps({ getReferenceClientRect: props.clientRect });
						},
						onKeyDown: (props) => component.ref?.onKeyDown(props),
						onExit: () => {
							component.destroy();
							popup.destroy();
						},
					};
				},
				pluginKey: PlaceholderPluginKey,
				command: ({ editor, range, props }) => {
					// increase range.to by one when the next node is of type "text"
					// and starts with a space character
					const nodeAfter = editor.view.state.selection.$to.nodeAfter;
					const overrideSpace = nodeAfter?.text?.startsWith(" ");

					if (overrideSpace) {
						range.to += 1;
					}

					editor
						.chain()
						.focus()
						.insertContentAt(range, [
							{
								type: this.name,
								attrs:
									props.type === "calculation"
										? {
												name: "calculation",
												value: "1+2+3",
												createdAt: Date.now(),
										  }
										: { name: props.id },
							},
							{ type: "text", text: " " },
						])
						.run();

					// get reference to `window` object from editor element, to support cross-frame JS usage
					editor.view.dom.ownerDocument.defaultView
						?.getSelection()
						?.collapseToEnd();
				},
			},
		};
	},

	addAttributes() {
		return {
			name: {
				default: "",
			},
			value: {
				default: "",
			},
			createdAt: {
				default: null,
			},
		};
	},

	addNodeView() {
		return ReactNodeViewRenderer(PlaceholderNodeViewRenderer);
	},

	parseHTML() {
		return [
			{
				tag: "span[data-variable]",
				getAttrs: (dom) => {
					return {
						name: dom.getAttribute("data-variable"),
					};
				},
			},
		];
	},

	renderHTML({ node }) {
		const isCalculation = node.attrs.name === "calculation";
		return [
			"span",
			{
				"data-variable": node.attrs.name,
				class: cn(
					"border px-1 rounded font-semibold",
					isCalculation
						? "bg-purple-200 border-purple-300 text-purple-700"
						: "bg-blue-200 border-blue-300 text-blue-700",
				),
			},
			isCalculation ? node.attrs.value : node.attrs.name,
		];
	},

	addCommands() {
		return {
			getPlaceholderFields: () => () => {
				return this.options.fields;
			},
			insertPlaceholderCalculation:
				() =>
				({ commands }) => {
					return commands.insertContent({
						type: this.name,
						attrs: {
							name: "calculation",
							value: "1+2+3",
							createdAt: Date.now(),
						},
					});
				},
			insertPlaceholderField:
				(name) =>
				({ commands }) => {
					return commands.insertContent({ type: this.name, attrs: { name } });
				},
		};
	},

	addProseMirrorPlugins() {
		return [
			Suggestion({
				editor: this.editor,
				...this.options.suggestion,
			}),
		];
	},
});
